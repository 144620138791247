const chatServiceUrl = 'https://chat.zhihua.chat/shop'
const isLocal = (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') && document.title === '智话销售';
const wxCloudServiceUrl = isLocal ? 'http://localhost:80' : 'https://zchat-bg-go-116838-7-1320884641.sh.run.tcloudbase.com';

async function fetchApi(endpoint, method = "POST", data = {}) {
    try {
        const response = await fetch(`${chatServiceUrl}${endpoint}`, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: method,
            body: method === 'GET' ? null : JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }
        return await response.json();
    } catch (error) {
        console.log(error);
        return null;
    }
}

async function getToken() {
    const token = localStorage.getItem('sales_token');
    return token ? `Bearer ${token}` : null;
}

async function fetchTokenApi(url, options = {}) {
    try {
        const token = await getToken(); // 等待 getToken 完成
        if (token) {
            options.headers = {
                ...(options.headers || {}),
                'Authorization': token
            };
        } else {
            window.location.href = '/login';
            console.log("未登录");
            return;
        }

        if (!options.headers['Content-Type']) {
            options.headers['Content-Type'] = 'application/json';
        }

        // 确保 body 被正确转换为字符串
        if (options.body && typeof options.body === 'object') {
            options.body = JSON.stringify(options.body);
        }

        const response = await fetch(`${wxCloudServiceUrl}${url}`, options);
        if (response.status === 401) {
            window.location.href = '/login';
            console.log("无权限");
            return;
        }
        if (response.status !== 200) {
            console.log('Error fetching API', response);
        }
        return response.json();

    } catch (error) {
        console.log('Network response was not ok', error);
    }
}


export async function wechatSinglechatApi(data) {//获取单条微信的信息
    return fetchApi(`/chat`, "POST", data)
}

export async function employApi(data) {//雇佣员工
    return fetchApi(`/employee`, "POST", data)
}

export async function loginWechatApi() {//登陆微信
    return fetchApi(`/login_wechat`, "POST")
}

export async function bindAgentApi(data) {//微信绑定agent
    return fetchApi(`/bind_agent`, "POST", data)
}

export async function getWxAccountsApi(need_head = true) {//获取所有微信账号
    return fetchApi(`/get_wx_accounts?need_head=${need_head}`, "GET")
}

export async function getWxUsersApi(wxAccount, need_head = true) {//获取微信助手的消息
    return fetchApi(`/get_wx_users?wx_user_id=${wxAccount}&need_head=${need_head}`, "GET")
}

export async function sendMessageApi(data) {//微信自定义发送消息
    return fetchApi(`/send_message`, "POST", data)
}

export async function login(username, password) {
    return fetch(`${wxCloudServiceUrl}/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            username,
            password
        })
    }).then(response => {
        if (response.status !== 200) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    });
}

export async function addProductApi(data) {//新增商品
    return fetchTokenApi(`/products`, { method: "POST", body: data })
}

export async function getProductsApi() {//新增商品
    return fetchTokenApi(`/products`, { method: "GET" })
}

export async function updateProductApi(id, data) {//新增商品
    return fetchTokenApi(`/products?id=${id}`, { method: "PUT", body: data })
}

export async function addCaseApi(data) {//新增商品
    return fetchTokenApi(`/cases`, { method: "POST", body: data })
}

export async function getCasesApi() {//新增商品
    return fetchTokenApi(`/cases`, { method: "GET" })
}

export async function updateCaseApi(id, data) {//新增商品
    return fetchTokenApi(`/cases?id=${id}`, { method: "PUT", body: data })
}